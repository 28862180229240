var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-wrapper"},[_c('BaseNavigation'),_c('DividerNavigation'),_c('div',{staticClass:"p-2 mx-auto"},[_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px"}},[_vm._v("Dokumen")]),_c('validation-observer',{ref:"mutasiMasukValidation"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Asal Cabang","label-for":"asal-cabang"}},[_c('validation-provider',{attrs:{"name":"asal-cabang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"asal-cabang","state":errors.length > 0 ? false : null,"name":"asal-cabang","type":"text"},model:{value:(_vm.form.mutmasuk_asal_cabang),callback:function ($$v) {_vm.$set(_vm.form, "mutmasuk_asal_cabang", $$v)},expression:"form.mutmasuk_asal_cabang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Asal Wilayah","label-for":"asal-wilayah"}},[_c('validation-provider',{attrs:{"name":"asal-wilayah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"asal-wilayah","state":errors.length > 0 ? false : null,"name":"asal-wilayah","type":"text"},model:{value:(_vm.form.mutmasuk_asal_wilayah),callback:function ($$v) {_vm.$set(_vm.form, "mutmasuk_asal_wilayah", $$v)},expression:"form.mutmasuk_asal_wilayah"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tujuan Cabang","label-for":"tujuan-cabang"}},[_c('b-form-input',{attrs:{"id":"tujuan-cabang","name":"tujuan-cabang","type":"text","value":"Jakarta Pusat","disabled":""}})],1),_c('b-form-group',{attrs:{"label":"Bukti iuran anggota pada cabang asal","label-for":"bukti-iuran"}},[_c('validation-provider',{attrs:{"name":"bukti-iuran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"bukti-iuran","state":errors.length > 0 ? false : null,"name":"bukti-iuran","accept":"image/*"},on:{"change":function($event){return _vm.onBuktiIuranChange($event)}},model:{value:(_vm.temp.mutmasuk_file_iuranasal),callback:function ($$v) {_vm.$set(_vm.temp, "mutmasuk_file_iuranasal", $$v)},expression:"temp.mutmasuk_file_iuranasal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.mutmasuk_file_iuranasal)?_c('b-img',{staticClass:"mt-1",staticStyle:{"max-height":"200px"},attrs:{"src":_vm.form.mutmasuk_file_iuranasal,"fluid":"","center":"","alt":"Bukti iuran anggota pada cabang asal"}}):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Surat Keterangan Pindah","label-for":"surat-pindah"}},[_c('validation-provider',{attrs:{"name":"surat-pindah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"surat-pindah","state":errors.length > 0 ? false : null,"name":"surat-pindah","accept":"image/*"},on:{"change":function($event){return _vm.onSuratKeteranganChange($event)}},model:{value:(_vm.temp.mutmasuk_file_ketpindah),callback:function ($$v) {_vm.$set(_vm.temp, "mutmasuk_file_ketpindah", $$v)},expression:"temp.mutmasuk_file_ketpindah"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.mutmasuk_file_ketpindah)?_c('b-img',{staticClass:"mt-1",staticStyle:{"max-height":"200px"},attrs:{"src":_vm.form.mutmasuk_file_ketpindah,"fluid":"","center":"","alt":"Surat Keterangan Pindah"}}):_vm._e()],1),_c('b-button',{attrs:{"type":"submit","variant":"outline-danger","block":""},on:{"click":_vm.validationForm}},[_vm._v(" Simpan ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }